import { createPanelDefs } from '../core/services/panel-definitions'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { EVENTS } from '../../constants/bi'
import * as Raven from 'raven-js'
import PANELS from '../core/manage-panels/consts/panel-names'
import { GFPP_IDS } from '../core/manifests/manifest-commons'
import { getApi } from '../editor-ready/editor-ready'
import { openComponentPanel, openPremiumBillingPanel } from '../core-actions'
import { getBiLogger, setBiLogger, setPanelDefinitions, getSiteId } from '../editor-app-impl'

export const handleGfppClicked = ({ componentRef, id }: { componentRef: any; id: GFPP_IDS }) => {
  const biLogger = getBiLogger()
  switch (id) {
    case GFPP_IDS.FORM_SETTINGS:
      openComponentPanel(componentRef, PANELS.SETTINGS_PANEL, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.MANAGE_FEILDS:
      openComponentPanel(componentRef, PANELS.MANAGE_FIELDS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.manageFieldsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.FIELD_SETTINGS:
      openComponentPanel(
        componentRef,
        PANELS.FEILD_SETTINGS_PANEL,
        ({ fieldType, crmLabel, formId }) =>
          biLogger.log({
            evid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL,
            field_type: fieldType,
            field_name: crmLabel,
            origin: 'gfpp',
            form_comp_id: formId,
          })
      )
      break
    case GFPP_IDS.FORM_STYLE:
      openComponentPanel(componentRef, PANELS.FORM_STYLE_PANEL)
      break
    case GFPP_IDS.FORM_LAYOUT:
      openComponentPanel(componentRef, PANELS.FORM_LAYOUT_PANEL, ({ columns, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
          action: 'open',
          layout: columns === 1 ? 'single' : columns ? `${columns} column` : '2 column',
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.CHANGE_BUTTON_CHANGED:
      openComponentPanel(componentRef, PANELS.BUTTON_CHANGE_TEXT_PANEL)
      break
    case GFPP_IDS.PREMIUM:
      openPremiumBillingPanel(componentRef)
      break
    default:
      break
  }
}

export const handleComponentDelete = async ({ componentRef, connections, historySnapshotId }) => {
  const api = await getApi()
  api.handleDelete(componentRef, connections[0], historySnapshotId)
}

export const handlePublish = async () => {
  const api = await getApi()
  await api.sendAllFormsData()
}

export const handleFirstSave = async ({ metaSiteId: newMsid }) => {
  const api = await getApi()
  if (newMsid) {
    Raven.setUserContext({ id: `msid_${newMsid}` })
    const panelDefinitions = createPanelDefs(newMsid)
    const biLogger = await initBiLoggerForEditorApp(newMsid)
    setBiLogger(biLogger)
    setPanelDefinitions(panelDefinitions)
    api.setBiLogger(biLogger)
  }
  const siteId = await getSiteId()
  await api.reportBiFirstSave(siteId)
}
