import * as _ from 'lodash'
import { createForm } from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import { SHOULD_CREATE_COLLECTION } from '../preset/presets-data'
import translations from '../services/translations'
import { undoable } from '../utils'
import { FormPreset } from '../../../constants/form-types'
import { EVENTS, ORIGINS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import { ROLE_FORM } from '../../../constants/roles'
import CollectionsApi from '../collections/api'
import CoreApi from '../core-api'
import { Theme } from '../../../constants/form-style'

const MASTER_PAGE = { type: 'DESKTOP', id: 'masterPage' }
const DEFAULT_CONTROLLER_LAYOUT = { x: 100, y: 20 }

const normalizeFormName = (formNames: string[], presetKey: string) => {
  const title = translations.t('formName', {
    name: translations.t(`addForm.templates.${presetKey}.label`),
  })

  return createSuffixedName(formNames, title)
}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private collectionsApi: CollectionsApi
  private experiments: any

  constructor(boundEditorSDK, coreApi, collectionsApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.collectionsApi = collectionsApi
    this.experiments = experiments
  }

  @undoable()
  public async addForm(
    presetKey: FormPreset,
    {
      containerRef = null,
      targetPageRef = null,
      coords = null,
      theme = Theme.THEME01,
      source_name = ORIGINS.APP_MARKET,
    } = {}
  ) {
    if (!containerRef) {
      await this.coreApi.saveSiteIfUnsaved()
    }

    const formName = await this._getFormName(presetKey)
    let formLabelId = ''
    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}
    const { box, fields: fieldsWithRqLabel } = createForm(presetKey, { coords, theme, formLabelId })
    const fields = this.experiments.enabled('specs.cx.FormBuilderRequiredIndication')
      ? fieldsWithRqLabel
      : fieldsWithRqLabel.map(field => _.omit(field, 'data.style.style.properties.txtlblrq'))

    const [pageRef, msid] = await Promise.all([
      targetPageRef || this.boundEditorSDK.pages.getCurrent(),
      this.coreApi.getMetaSiteId(),
    ])

    const controllerRef = await this._addController(pageRef)
    this.coreApi.appState.setState([controllerRef])

    let formRef
    if (containerRef) {
      await this.coreApi.connect(
        _.merge({}, box, {
          connectionConfig: {
            formName,
            msid,
            theme,
          },
        }),
        controllerRef,
        containerRef
      )

      this._removeLoader(containerRef)

      const {
        data: {
          layout: { width, height },
        },
      } = box
      this.boundEditorSDK.components.layout.update({
        componentRef: containerRef,
        layout: { height, width },
      })
      formRef = containerRef
    } else {
      formRef = (await this.coreApi.addComponentAndConnect(
        _.merge({}, box, { connectionConfig: { formName, msid, theme, formLabelId } }),
        controllerRef,
        pageRef
      )).connectToRef

      await this._fixFormY(formRef)
    }

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.CHOOSE_TEMPLATE,
      template: presetKey,
      form_comp_id: formRef.id,
      source_name,
    })

    const fieldsData = await Promise.all(
      fields.map(async field => {
        const { role, connectionConfig } = await this.coreApi.addComponentAndConnect(
          _.merge({}, field, {
            connectionConfig: {
              collectionFieldKey: _.camelCase(field.connectionConfig.crmLabel),
            },
          }),
          controllerRef,
          formRef
        )
        return _.merge({ role }, connectionConfig)
      })
    )

    this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [formRef] })

    this._updateFormOwnerEmailId(formRef)

    if (!containerRef) {
      this._createAutoCollection(formRef, presetKey, fieldsData).then(collectionId => {
        if (!this.experiments.enabled('specs.cx.FormBuilderDisableInitialEditDraft')) {
          this.coreApi.editDraft(formRef, formName, collectionId)
        }

        if (this.experiments.enabled('specs.cx.FormBuilderSaveOnCreateCollection')) {
          this.coreApi.saveSite()
        }
      })
    } else {
      if (!this.experiments.enabled('specs.cx.FormBuilderDisableInitialEditDraft')) {
        this.coreApi.editDraft(formRef, formName)
      }
    }

    return formRef
  }

  private async _addController(pageRef) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const pageData = await this.boundEditorSDK.components.data.get({
      componentRef: pageRef,
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: {
        componentType: 'platform.components.AppController',
        layout: DEFAULT_CONTROLLER_LAYOUT,
        data: {
          controllerType: 'singlePostController',
          name: 'wix-forms-controller',
          applicationId: appDefinitionId,
          settings: JSON.stringify({
            dataset: {
              collectionName: 'test',
              readWriteType: 'READ',
              filter: null,
              sort: null,
            },
          }),
        },
      },
      pageRef: _.get(pageData, 'isPopup') ? pageRef : MASTER_PAGE,
    })
  }

  private async _updateFormOwnerEmailId(componentRef: ComponentRef): Promise<void> {
    const emailId = await this.coreApi.getOwnerEmailId()
    this.coreApi.setComponentConnection(componentRef, { emailId })
  }

  private async _fixFormY(componentRef: ComponentRef): Promise<void> {
    const { height, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })
    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { y: _.max([0, y - height / 2]) },
    })
  }

  private async _createAutoCollection(
    componentRef: ComponentRef,
    presetKey: FormPreset,
    fieldsData
  ): Promise<string | null> {
    const shouldCreateCollection = SHOULD_CREATE_COLLECTION[presetKey]

    if (!shouldCreateCollection) {
      return Promise.resolve(null)
    }

    const startCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }
    const endCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }

    const collectionId = await this.collectionsApi.createCollection(
      { preset: presetKey },
      { startBi: startCollectionBi, endBi: endCollectionBi }
    )
    if (!collectionId) {
      return null
    }

    return Promise.all([
      this.coreApi.setComponentConnection(componentRef, { collectionId }),
      this.collectionsApi.addFieldsToCollection(
        collectionId,
        fieldsData,
        (fieldComponent, fieldKey) =>
          this.coreApi.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
      ),
    ]).then(() => collectionId)
  }

  private async _getFormName(presetKey): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        const {
          config: { formName },
        } = formRef
          ? await this.coreApi.getComponentConnection(formRef)
          : { config: { formName: '' } }
        return formName
      })
    )
    return normalizeFormName(formNames, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  private async _removeLoader(componentRef): Promise<void> {
    return this.boundEditorSDK.components.style.update({
      componentRef: componentRef,
      style: { loader: 0 },
    })
  }
}
