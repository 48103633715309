import { ComponentRef } from '../api-types'
import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { AppStateBuilder } from './app-state-builder'
import { ROLE_FORM } from '../../../constants/roles'
import { isAscendEnabled } from '../../../utils/utils'

export default class AppState {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _isTopPremium() {
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return restrictions.isTopPremium
  }

  private async _isRegistration(controllerRef) {
    const formRef: ComponentRef = await this.coreApi.findConnectedComponent(
      controllerRef,
      ROLE_FORM
    )
    return await this.coreApi.isRegistrationForm(formRef)
  }

  public async setState(controllerRefs?: ComponentRef[]) {
    if (!controllerRefs) {
      const controllers: {
        controllerRef: ComponentRef
      }[] = await this.boundEditorSDK.controllers.listAllControllers()
      controllerRefs = controllers.map(({ controllerRef }) => controllerRef)
    }

    const isTopPremium = await this._isTopPremium()
    const isAscendExperimentEnabled = await isAscendEnabled(this.experiments)

    controllerRefs.forEach(async ref => {
      const registration = await this._isRegistration(ref)

      const stateBuilder = new AppStateBuilder({
        isTopPremium,
        registration,
        isAscendEnabled: isAscendExperimentEnabled,
      })
      const stateName = stateBuilder.toString()

      if (stateName) {
        await this.boundEditorSDK.controllers.setState({ state: { [stateName]: [ref] } })
      }
    })
  }
}
