export const preset = t => ({
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 510,
        height: 33,
        x: 60,
        y: 35,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
            f10: 'font_10',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
            f10: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'title_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text: `<h5 class='font_5'><span style='font-size:30px;'>${t(
          'preset.jobApplicationTitleText'
        )}</span></h5>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 510,
        height: 21,
        x: 60,
        y: 489,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'message_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text: `<p class="font_8"><span style="color:#8FCA8F;">${t(
          'preset.successMessageText'
        )}</span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      layout: {
        width: 142,
        height: 40,
        x: 60,
        y: 440,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg36r1',
      componentType: 'wysiwyg.viewer.components.SiteButton',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jatpg36r2',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brdh': '0',
            'alpha-txt': '1',
            'alpha-txth': '1',
            bg: 'color_18',
            bgh: 'color_17',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_14',
            brdh: 'color_12',
            brw: '0',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_11',
            txth: 'color_11',
          },
          propertiesSource: {
            'alpha-brdh': 'value',
            bg: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brdh: 'theme',
            brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txth: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      role: 'button_Role',
      config: {},
      props: {
        type: 'ButtonProperties',
        metaData: { schemaVersion: '1.0' },
        align: 'center',
        margin: 0,
      },
      data: {
        type: 'LinkableButton',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        label: t('preset.submitButtonLabel'),

        link: {
          type: 'FormSubmitButtonLink',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 330,
        y: 103,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg370',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausu926',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.lastNameCrmLabel'),

        fieldType: 'lastName',
        crmType: 'lastName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.lastNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'text',
        maxLength: 100,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 186,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg37a',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausuex7',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.emailCrmLabel'),
        fieldType: 'email',
        crmType: 'email',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.emailCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'email',
        maxLength: 250,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 330,
        y: 186,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg37n1',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausum7d',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.phoneCrmLabel'),
        fieldType: 'phone',
        crmType: 'phone',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.phoneCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'tel',
        maxLength: 50,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 103,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg37x',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausu2xq',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.firstNameCrmLabel'),

        fieldType: 'firstName',
        crmType: 'firstName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.firstNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'text',
        maxLength: 100,
      },
    },
    {
      type: 'Component',
      skin: 'ComboBoxInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 268,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatpg3881',
      componentType: 'wysiwyg.viewer.components.inputs.ComboBoxInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausv8i7',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-arrowColor': '0.5',
            'alpha-bgd': '0',
            'alpha-brd': '0.55',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            arrowColor: 'color_15',
            bg: 'rgba(255, 255, 255, 1)',
            bg2: '#aaaaaa',
            bgd: '#D4D4D4',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#D4D4D4',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.ComboBoxInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'ComboBoxInputSkin',
      },
      role: 'field_role_select',
      config: {
        crmLabel: t('preset.positionCrmLabel'),

        fieldType: 'generalDropDown',
        crmType: 'customField',
        customFieldId: '',
      },
      props: {
        type: 'ComboBoxInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: { value: 'Position you apply for', text: 'Position you apply for' },
      },
      data: {
        type: 'SelectableList',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        options: [
          {
            type: 'SelectOption',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.positionSelectFirstOptionValue'),

            text: `${t('preset.positionSelectFirstOptionText')}`,
            description: t('preset.positionSelectFirstOptionDescription'),

            disabled: false,
          },
          {
            type: 'SelectOption',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.positionSelectSecondOptionValue'),

            text: `${t('preset.positionSelectSecondOptionText')}`,
            description: t('preset.positionSelectSecondOptionDescription'),

            disabled: false,
          },
          {
            type: 'SelectOption',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.positionSelectThirdOptionValue'),

            text: `${t('preset.positionSelectThirdOptionText')}`,
            description: t('preset.positionSelectThirdOptionDescription'),

            disabled: false,
          },
          {
            type: 'SelectOption',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.positionSelectFourthOptionValue'),

            text: `${t('preset.positionSelectFourthOptionText')}`,
            description: t('preset.positionSelectFourthOptionDescription'),

            disabled: false,
          },
        ],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin',
      layout: {
        width: 240,
        height: 42,
        x: 330,
        y: 268,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-j9445bih1',
      componentType: 'wysiwyg.viewer.components.inputs.DatePicker',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jb0jgb41',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            'alpha-icn-color': '0.5',
            bg: 'color_11',
            'bg-calendar': 'color_11',
            'bg-day-selected': 'color_18',
            'bg-header': 'color_11',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            'boxShadowToggleOn-shd-calendar': 'false',
            brd: 'color_15',
            'brd-calendar': 'color_15',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            'brw-calendar': '1',
            brwf: '1',
            brwh: '1',
            fnt: 'font_8',
            'fnt-family-day': 'avenir-lt-w01_35-light1475496',
            'fnt-family-header': 'avenir-lt-w01_35-light1475496',
            'icn-color': 'color_15',
            rd: '0px',
            'rd-calendar': '0px',
            'rd-day-selected': '30px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            'shd-calendar': '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-day': 'color_15',
            'txt-day-selected': 'color_11',
            'txt-header': 'color_15',
            'txt-placeholder': 'color_14',
            'txt-size-day': '13',
            'txt-size-header': '16',
            txt2: 'color_13',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            'alpha-icn-color': 'value',
            bg: 'theme',
            'bg-calendar': 'theme',
            'bg-day-selected': 'theme',
            'bg-header': 'theme',
            bgcd: 'value',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            'brd-calendar': 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            'brw-calendar': 'value',
            brwf: 'value',
            brwh: 'value',
            fnt: 'theme',
            'fnt-family-day': 'value',
            'fnt-family-header': 'value',
            'icn-color': 'theme',
            rd: 'value',
            'rd-calendar': 'value',
            'rd-day-selected': 'value',
            shd: 'value',
            'shd-calendar': 'value',
            txt: 'theme',
            'txt-day': 'theme',
            'txt-day-selected': 'theme',
            'txt-header': 'theme',
            'txt-placeholder': 'theme',
            'txt-size-day': 'theme',
            'txt-size-header': 'value',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.DatePicker',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin',
      },
      role: 'field_role_date',
      config: {
        crmLabel: t('preset.startDateDatePickerCrmLabel'),

        fieldType: 'generalDatePicker',
        crmType: 'customField',
        customFieldId: '',
      },
      props: {
        type: 'DatePickerProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.startDateDatePickerPlaceholder'),

        dateFormat: 'MM/DD/YYYY',
        defaultTextType: 'placeholder',
      },
      data: {
        type: 'DatePicker',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        disabledDates: [],
        disabledDaysOfWeek: [],
        allowPastDates: false,
        allowFutureDates: true,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 350,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-j9445bid',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jjq6nyuq',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '1',
            'alpha-brdf': '0.55',
            'alpha-brdh': '0.55',
            'alpha-btn_brd': '0.55',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: '#fbf3f3',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#f60419',
            brdf: 'color_15',
            brdh: 'color_15',
            brw: '1',
            brwd: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            btn_brd: 'color_15',
            btn_brw: '1',
            btn_fnt: 'font_8',
            fnt: 'font_8',
            fnt2: 'font_8',
            rd: '0pxpx',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-placeholder': 'color_14',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmType: 'customField',
        crmLabel: 'Resume URL',
        collectionFieldKey: 'urlField',
        fieldType: 'generalURL',
        collectionFieldType: 'url',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: 'Link to your Resume',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        id: 'dataItem-jjq6nyup',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
        pattern: '^((https?|ftp)://)?[^\\s/$.?#]*\\.[^\\s]*$',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    width: 630,
    height: 555,
    x: 175,
    y: 15,
    scale: 1.0,
    rotationInDegrees: 0.0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.FormContainer',
  role: 'form_Role',
  config: { preset: 'contact-form', labels: ['contacts-contacted_me'], emailId: '' },
})
