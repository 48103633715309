import * as _ from 'lodash'
import { createBoxManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import {
  ROLE_FORM,
  FIELDS_ROLES,
  ROLE_SUBMIT_BUTTON,
  ROLE_MESSAGE,
  ROLE_TITLE,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const states = _.flatMap([true, false], isTopPremium =>
  _.flatMap([true, false], registration =>
    _.flatMap(
      [true, false],
      isAscendEnabled => new AppStateBuilder({ isTopPremium, registration, isAscendEnabled })
    )
  )
).filter(x => x.toString())

export const getAppManifest = () => {
  const fieldManifests = FIELDS_ROLES.reduce(
    (res, fieldRole) => ({
      ...res,
      [fieldRole]: createFieldsManifests()[fieldRole],
    }),
    {}
  )
  const submitButtonManifest = createSubmitButtonManifest()
  const hiddenMessageManifes = createHiddenMessageManifest()
  const titleManifest = createTitleManifest()
  const createManifest = (state: AppStateObject) => ({
    visibility: ALWAYS_HIDE_CONTROLLER,
    connections: {
      [ROLE_FORM]: createBoxManifest(state),
      [ROLE_SUBMIT_BUTTON]: submitButtonManifest,
      [ROLE_MESSAGE]: hiddenMessageManifes,
      [ROLE_TITLE]: titleManifest,
      ...fieldManifests,
    },
  })

  return {
    controllersStageData: {
      singlePostController: {
        default: createManifest({}),
        ...states.reduce((agg, state) => {
          agg[state.toString()] = createManifest(state.get())
          return agg
        }, {}),
      },
    },
  }
}
