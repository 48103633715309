import translations from '../services/translations'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'

export const createBoxManifest = ({
  isTopPremium,
  registration,
  isAscendEnabled,
}: AppStateObject = {}) => {
  const displayName = registration ? 'registrationFormBuilder' : 'formBuilder'
  const extraIconButtons = isTopPremium
    ? {}
    : {
        [GFPP_ACTIONS.UPGRADE]: isAscendEnabled
          ? GFPP.KEEP_DEFAULT
          : {
              actionId: GFPP_IDS.PREMIUM,
            },
      }
  return {
    showFocusBox: true,
    displayName: translations.t(displayName),
    behavior: { duplicatable: false },
    gfpp: {
      desktop: {
        mainAction1: {
          actionId: GFPP_IDS.MANAGE_FEILDS,
          label: translations.t('manageFields.gfppTitle'),
        },
        mainAction2: GFPP.REMOVE,
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: {
            actionId: GFPP_IDS.FORM_SETTINGS,
          },
          [GFPP_ACTIONS.LAYOUT]: {
            actionId: GFPP_IDS.FORM_LAYOUT,
          },
          [GFPP_ACTIONS.DESIGN]: {
            actionId: GFPP_IDS.FORM_STYLE,
          },
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          ...extraIconButtons,
        },
        helpId: registration
          ? GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER
          : GFPP.HELP_ID.FORM_CONTAINER,
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }
}
