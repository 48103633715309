import { FIELDS, ROLE_FORM } from '../../../constants/roles'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import translations from '../services/translations'

const manifest = ({ keepMainAction, keepSettingsIcon, registration = false }) => {
  const desktopMainActions = {
    mainAction1: keepMainAction ? GFPP.KEEP_DEFAULT : GFPP.REMOVE,
    mainAction2: {
      actionId: GFPP_IDS.FIELD_SETTINGS,
      label: translations.t('fieldSettings.gfppTitle'),
    },
  }
  const res: any = {
    relatedToRole: ROLE_FORM,
    displayName: translations.t('fields.labelName'),
    behavior: { duplicatable: false },
    gfpp: {
      desktop: {
        ...desktopMainActions,
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: keepSettingsIcon ? GFPP.KEEP_DEFAULT : GFPP.REMOVE,
          [GFPP_ACTIONS.LAYOUT]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.LAYOUT]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
        },
        helpId: registration ? GFPP.HELP_ID.REGISTRATION_FIELD : GFPP.HELP_ID.FIELD,
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FIELD_MOBILE,
      },
    },
  }
  return res
}

export const createFieldsManifests = () => ({
  [FIELDS.ROLE_FIELD_TEXT]: manifest({ keepMainAction: false, keepSettingsIcon: false }),
  [FIELDS.ROLE_FIELD_TEXT_AREA]: manifest({ keepMainAction: false, keepSettingsIcon: false }),
  [FIELDS.ROLE_FIELD_SELECT]: manifest({ keepMainAction: true, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_RADIO_LIST]: manifest({ keepMainAction: true, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_CHECKBOX]: manifest({ keepMainAction: false, keepSettingsIcon: false }),
  [FIELDS.ROLE_FIELD_CHECKBOX_GROUP]: manifest({ keepMainAction: true, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_SUBSCRIBE]: manifest({ keepMainAction: false, keepSettingsIcon: false }),
  [FIELDS.ROLE_FIELD_DATE]: manifest({ keepMainAction: false, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_FILE_UPLOAD]: manifest({ keepMainAction: false, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_RATING]: manifest({ keepMainAction: true, keepSettingsIcon: true }),
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: manifest({
    keepMainAction: false,
    keepSettingsIcon: false,
    registration: true,
  }),
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: manifest({
    keepMainAction: false,
    keepSettingsIcon: false,
    registration: true,
  }),
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: manifest({
    keepMainAction: false,
    keepSettingsIcon: false,
    registration: true,
  }),
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: manifest({
    keepMainAction: false,
    keepSettingsIcon: false,
    registration: true,
  }),
})
