import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { COMPONENT_TYPES } from '../../component-types'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeCheckboxAgreeTerms = (): Field => {
  const t = translations.t.bind(translations)
  return _.merge(
    {},
    {
      componentType: COMPONENT_TYPES.SINGLE_CHECKBOX,
      extraData: {
        role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS,
        connectionConfig: {
          crmType: CRM_TYPES.CUSTOM_FIELD,
          crmLabel: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}`),
        },
        data: {
          label: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}.label`),
        },
      },
    }
  )
}
