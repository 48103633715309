import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationFormMessageStructure from '../../../assets/presets/registration-form-message.json'
import { MessageType } from '../../../constants/field-types'

export const getSubmitButtonPreset = () => {
  return submitButtonStructure
}
export const getHiddenMessagePreset = messageType => {
  if (messageType === MessageType.REGISTRATION) {
    return registrationFormMessageStructure
  }
  return hiddenMessageStructure
}
