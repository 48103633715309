import * as _ from 'lodash'
import * as textInput from './styles/textInput.json'
import * as datePicker from './styles/datePicker.json'
import * as dropdown from './styles/dropdown.json'
import * as textArea from './styles/textArea.json'
import * as radio from './styles/radio.json'
import * as button from './styles/button.json'
import * as box from './styles/box.json'
import * as theme01 from '../../../assets/components/theme01.json'
import * as theme02 from '../../../assets/components/theme02.json'
import * as theme03 from '../../../assets/components/theme03.json'
import * as theme04 from '../../../assets/components/theme04.json'
import * as theme05 from '../../../assets/components/theme05.json'
import * as theme06 from '../../../assets/components/theme06.json'
import { Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { FIELDS } from '../../../constants/roles'

const handlePropertiesAsStyle = properties => {
  const propertiesSource = _.mapValues(properties, 'theme')

  return comp => {
    return _.merge({}, comp, { style: { style: { properties, propertiesSource } } })
  }
}

const handleByType = {
  'wysiwyg.viewer.components.inputs.TextInput': handlePropertiesAsStyle(textInput),
  'wysiwyg.viewer.components.inputs.TextAreaInput': handlePropertiesAsStyle(textArea),
  'wysiwyg.viewer.components.inputs.DatePicker': handlePropertiesAsStyle(datePicker),
  'wysiwyg.viewer.components.inputs.ComboBoxInput': handlePropertiesAsStyle(dropdown),
  'wysiwyg.viewer.components.inputs.RadioGroup': handlePropertiesAsStyle(radio),
  'wysiwyg.viewer.components.SiteButton': handlePropertiesAsStyle(button),
  'mobile.core.components.Container': handlePropertiesAsStyle(box),
}

export const customStyleHandlers = {
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG]: (style, text) =>
    _.replace(style, 'TITLE', text),
}

export const applyStyle = (comp, theme) => {
  if (theme) {
    const style = getThemeStyle(theme)[comp.role]

    if (!style) {
      return comp
    }

    if (!_.isString(style)) {
      return handlePropertiesAsStyle(style)(comp)
    }

    const customStyleHandler = customStyleHandlers[comp.role]
    const text = comp.data.text
    const newText = customStyleHandler
      ? customStyleHandler(style, text)
      : _.replace(style, 'TITLE', innerText(text))

    return _.merge({}, comp, { data: { text: newText } })
  }

  const styleHandler = handleByType[comp.componentType]
  return styleHandler ? styleHandler(comp) : comp
}

export const applyPropsAndData = (comp, theme) =>
  _.merge({}, comp, {
    props: getThemeProps(theme)[comp.role],
    data: getThemeData(theme)[comp.role],
  })

const THEMES_STYLES = {
  [Theme.THEME01]: theme01,
  [Theme.THEME02]: theme02,
  [Theme.THEME03]: theme03,
  [Theme.THEME04]: theme04,
  [Theme.THEME05]: theme05,
  [Theme.THEME06]: theme06,
}

export const getThemeStyle = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'style'], {})
export const getThemeProps = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'props'], {})
export const getThemeData = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'data'], {})
export const removeRequiredIndicationFromTheme = themeStyle =>
  Object.keys(themeStyle).reduce((acc, key) => {
    acc[key] = _.omit(themeStyle[key], 'txtlblrq')
    return acc
  }, {})
