import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { Field } from '../../field-types-data'
import translations from '../../../../services/translations'
import { ConnectedElementPreset } from '../../../../../../constants/field-types'

export const makeLinkToLogin = (): Field => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      data: {
        text: t(`fieldTypes.${ConnectedElementPreset.REGISTRATION_FORM_LINK_TO_LOGIN}.text`),
      },
    },
  }
}
