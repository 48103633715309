import * as _ from 'lodash'
import { ColorPalette, ComponentRef } from '../api-types'
import { isAnyField, undoable, withBi } from '../utils'
import CoreApi from '../core-api'
import { calcCommonStyle, getStyleValues, isFormStyle } from '../services/form-style-service'
import {
  customStyleHandlers,
  getThemeData,
  getThemeProps,
  getThemeStyle,
  removeRequiredIndicationFromTheme,
} from '../preset/preset-styles'
import { FormAlphaStyle, FormStyle, Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { EVENTS } from '../../../constants/bi'

const paletteToMatrix = palette => {
  const result = [[], [], [], [], []]

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 5; j++) {
      result[i][j] = palette[`color_${i * 5 + j + 11}`]
    }
  }

  return result
}
export default class StyleApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private biLogger: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateFieldsStyle(
    componentRef: ComponentRef,
    {
      styleName,
      newStyleValue,
      commonStyles,
    }: { styleName: FormStyle | FormAlphaStyle; newStyleValue: string; commonStyles: object },
    _biData
  ) {
    let fields
    if (isFormStyle(styleName)) {
      fields = [{ componentRef }]
    } else {
      fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
        allFieldsTypes: true,
      })
    }

    return Promise.all(
      fields.map(({ componentRef: fieldRef }) =>
        this._updateCompStyle(fieldRef, { styleName, newStyleValue, commonStyles })
      )
    )
  }

  public async getColorsPalette(): Promise<ColorPalette> {
    const palette = await this.boundEditorSDK.theme.colors.getAll()
    return { colorsPalette: paletteToMatrix(palette), colors: palette }
  }

  public openColorPicker(options, onColorChange) {
    return this.boundEditorSDK.editor.openColorPicker(options, onColorChange)
  }

  public getFontsOptions() {
    return this.boundEditorSDK.fonts.getFontsOptions()
  }

  public getThemedFonts() {
    return this.boundEditorSDK.theme.fonts.getMap()
  }

  public async getFieldsCommonStyles(componentRef: ComponentRef) {
    const compStyle = await this.boundEditorSDK.components.style.get({ componentRef })
    const form = compStyle || { style: { properties: {} } }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const children = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })
    const fields = _.flatMap(children, ({ connections, style }) =>
      isAnyField(_.get(connections, '[0].role'))
        ? {
            style: _.get(style, 'style.properties'),
          }
        : []
    )

    return calcCommonStyle(form, fields)
  }

  private async _updateThemeStyle(componentRef: ComponentRef, style, customStyleHandler) {
    if (!style) {
      return
    }
    if (!_.isString(style)) {
      return this.boundEditorSDK.components.style.update({ componentRef, style })
    }

    const { text } = await this.boundEditorSDK.components.data.get({ componentRef })
    const newText = customStyleHandler
      ? customStyleHandler(style, text)
      : _.replace(style, 'TITLE', innerText(text))

    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { text: newText },
    })
  }

  private _updateThemeProps(componentRef: ComponentRef, props) {
    if (!props) {
      return
    }
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props,
    })
  }

  private _updateThemeData(componentRef: ComponentRef, data) {
    if (!data) {
      return
    }
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data,
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: Theme, _biData) {
    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const stylesByRole = getThemeStyle(theme)
    const styleAfterRemove = !this.experiments.enabled('specs.cx.FormBuilderRequiredIndication')
      ? removeRequiredIndicationFromTheme(stylesByRole)
      : stylesByRole
    const propsByRole = getThemeProps(theme)
    const dataByRole = getThemeData(theme)

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)

        return Promise.all([
          this._updateThemeProps(childRef, propsByRole[role]),
          this._updateThemeData(childRef, dataByRole[role]),
          this._updateThemeStyle(childRef, styleAfterRemove[role], customStyleHandlers[role]),
        ])
      })
    )
  }

  private async _updateCompStyle(
    componentRef: ComponentRef,
    { styleName, newStyleValue, commonStyles }
  ) {
    const {
      style: { properties } = { properties: {} },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    return this.boundEditorSDK.components.style.update({
      componentRef,
      style: _.merge({}, properties, getStyleValues(styleName, newStyleValue, commonStyles)),
    })
  }
}
