export interface AppStateObject {
  isTopPremium?: boolean
  registration?: boolean
  isAscendEnabled?: boolean
}

export class AppStateBuilder {
  constructor(private stateObject: AppStateObject = {}) {
    //
  }

  get = () => this.stateObject
  toString = () =>
    Object.keys(this.stateObject)
      .filter(k => this.stateObject[k])
      .sort()
      .join('-')
}
